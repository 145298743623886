@import "../../../../mixins";

.live-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    position: relative; }

.youTube {
    &-container {
        margin: 0 auto;
        @include gt-sm {
            margin: 0 7vw; } }
    &-video {
        width: 100%;
        height: calc( 100vw / 1.77777777 );
        max-height: 600px;
        border-radius: 10px;
        overflow: hidden; } }

.live-title {
    margin-top: 20px;
    margin-bottom: 10px;
    @include gt-sm {
        margin-top: 30px; }
    &-wrapper {
        margin: 0 3.5vw;
        @include gt-sm {
            margin: 0 7vw; } }

    span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 33px;
        color: #AB0B0B;
        @include gt-sm {
            font-size: 24px; } } }

.live-video-icon {
    position: absolute;
    bottom: 30px;
    right: calc( (100vw - 65vw) / 2 - 8.5vw );
    width: 35px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 5px;
    color: #ffffff;
    font-family: "Open sans";
    font-weight: 400;
    font-size: 10px;
    background-color: #AB0B0B;
    @include gt-xs {
        bottom: 35px;
        width: 50px;
        height: 35px;
        line-height: 35px;
        font-size: 14px; }
    @include gt-sm {
        width: 70px;
        height: 50px;
        line-height: 50px;
        font-size: 18px; }

    &-text {
        animation: pulse 2s ease-in-out infinite;
        @keyframes pulse {
            0% {
                transform: scale(0);
                opacity: 1; }
            50% {
                transform: scale(1.2);
                opacity: 0; }
            100% {
                transform: scale(0);
                opacity: 1; } } } }
