// // imports
// @import mixins
// @import ./components/home/home
@import '../node_modules/@splidejs/splide/dist/css/themes/splide-default.min.css';

//components css
@import "./components/home/home";
@import "./mixins";

@import "./components/news/news";
@import "./components/contacts/contacts";
@import "./components/about/about";
@import "./components/radio/radio";
@import "./components/perfTv/perfTv";
@import "./components/program/program";
@import "./components/team/team";
@import "./components/mainLayout/Footer/footer";
@import "./components/mainLayout/Header/header";
@import "./components/reusableComponents/resuableTitle/reusable";
@import "./components/reusableComponents/callToActionParticipate/callToActionParticipate";
@import "./helpers/loading";

//font family
@font-face {
    font-family: "Open sans";
    src : url("./assets/fonts/ReenieBeanie-Regular.ttf"); }
@font-face {
    font-family: "Open Sans";
    src: url('./assets/fonts/OpenSans-Regular.ttf'); }

// reset styles

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp,small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,fieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, embed,figure, figcaption, footer, header, hgroup,menu, nav, output, ruby, section, summary,time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block; }

body {
    line-height: 1; }

ol, ul {
    list-style: none; }

blockquote, q {
    quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

table {
    border-collapse: collapse;
    border-spacing: 0; }

//  Main styles

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

body {
    background-color: #FDFFFF; }

a {
    text-decoration: none; }

.content-wrapper {
    width: 100%;
    padding: 0 2.5%;
    @include gt-sm {
        padding: 0 10%; } }

.splide--draggable {
    .splide__track {
        .splide__list {
            .splide__slide {
                text-align: center; } } } }

.splide__arrow {
    top: 34%;
    margin-left: -4%;
    background: #ffffff;
    fill: #135272;
    outline: none; }

.splide__arrow.splide__arrow--next {
    margin-right: -3%;
    background: #ffffff;
    fill: #135272;
    outline: none; }

.row {
    width: 100%;
    height: 50%;
    display: flex;
    margin-top: 5%; }

.pagination-ul {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    .page {
        &-item {
            padding: 3px 0;
            display: inline-block;
            cursor: pointer; }
        &-link {
            position: relative;
            display: block;
            padding: .5rem .75rem;
            margin-left: -1px;
            line-height: 1.25;
            color: #007bff;
            background-color: #fff;
            border: 1px solid #dee2e6;
            &:hover {
                background-color: #e9ecef;
                border-color: #dee2e6; } } } }

.title-wrapp {
    position: absolute;
    left: 50%;
    padding: 10px 20px;
    transform: translateX(-50%);
    width: 95%;
    bottom: 20px;
    border-radius: 10px;
    background: rgba(196, 196, 196, 0.85);
    transition: all .35s ease;

    span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        text-align: left;
        color: #000; } }

.pagination-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    .selected {
        background-color: #e9ecef; } }

iframe {
    max-width: 100%; }

strong {
    font-weight: bold; }
