.ourTeam-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 60px;
    margin-bottom: 70px; }

.ourTeam-img-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.row {
    width: 100%;
    height: 50%;
    display: flex;
    margin-top: 5%; }

.name {
    margin-top: 10px;
    text-align: center;
    span {
        text-align: center;
        font-family: Open sans;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #276A8C; } }

.position {
    margin-top: 5px;
    text-align: center;
    span {
        text-align: center;
        font-family: Open sans;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #000; } }


.img-our-team {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    @include gt-xs {
        width: 125px;
        height: 125px; }
    @include gt-sm {
        width: 140px;
        height: 140px; }
    @include gt-md {
        width: 170px;
        height: 170px; } }
