.program {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    text-align: center;
    &-date {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px 10px;
        border-radius: 10px;
        background: rgba(196, 196, 196, 0.85);
        transition: all .35s ease;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 25px;
        text-align: center;
        z-index: 999;
        @include gt-xs {
            font-size: 13px; }
        @include gt-sm {
            font-size: 14px; } }

    &-container {
        width: 100%;
        margin-top: 7.5%;
        margin-bottom: 5%; }

    &-image-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap; }

    &-wrapp {
        max-width: 100%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        margin-bottom: 15px;
        padding-left: 15px;
        @include gt-xs {
            max-width: 50%; }
        @include gt-sm {
            max-width: 50%; }
        @include gt-md {
            max-width: 33.333333%; }

        &:hover {
            img {
                transform: scale(1.1); } }
        img {
            height: 220px;
            transition: all .35s ease; } } }

.program-page {
    &-container {
        width: 100%;
        margin-bottom: 50px;
        overflow: hidden; }

    &-image {
        margin-top: 40px;
        text-align: center;
        img {
            max-width: 100%;
            max-height: 400px;
            border-radius: 10px; } }

    &-text-wrapper {
        padding: 20px;
        font-size: 1.1em;
        overflow: hidden;

        img {
            max-width: 100%;
            height: auto;
            border-radius: 10px; }

        h1, h2, h3, h4 {
            margin: 20px; }

        a {
            color: #135272; } }

    &-title {
        text-align: center;
        margin-bottom: 50px; } }
