
.call-to-action-participate {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 130px;
    margin-bottom: 110px;

    button {
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        margin: 10px;
        background: #F4D155;
        border: 1px solid #135272;
        box-sizing: border-box;
        border-radius: 15px;
        font-size: 20px;
        outline: none;
        @include gt-xs {
            font-size: 21px;
            height: 45px;
            line-height: 45px; }
        @include gt-sm {
            font-size: 22px; }
        @include gt-md {
            font-size: 23px; }
        a {
            text-decoration: none; }
        span {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            text-align: center;
            letter-spacing: 0.04em;
            color: #135272; } } }



