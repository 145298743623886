.partners {
    &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 60px;
        margin-bottom: 70px; }

    &-img-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center; }

    &-name {
        text-align: center;
        margin-top: 10px;
        span {
            text-align: center;
            font-family: Reenie Beanie;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 22px;
            color: #276A8C; } }

    &-img {
        width: 100px;
        height: 100px;
        border-radius: 10px; } }

