.tv-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 53px;

    .title-line {
        width: 40%;
        height: 2px;
        background-color: #29698A;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-top: 2.5%; }

    .title-text {
        width: 20%;
        text-align: center; }

    .title-text {
        span {
            font-family: Reenie Beanie;
            font-style: normal;
            font-weight: 500;
            font-size: 64px;
            line-height: 64px;
            color: #276A8C; } }
    .tv {
        font-weight: 800; }

    .flex {
        width: 100%;
        display: flex;
        margin-top: 1%; }

    .col-left {
        position: relative;
        width: 50%;
        padding-left: 1%; }

    .col-left-img {
        width: 536px;
        height: 501px; }

    .col-right {
        width: 50%;
        display: flex;
        padding-left: 1%;
        padding-bottom: 1%;
        flex-direction: column;
        justify-content: space-between; }

    .col-right-top {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 1.5%;
        position: relative; }

    .col-right-top-img {
        width: 496px;
        height: 243px; }

    .col-right-bottom {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        position: relative; }

    .col-right-bottom-img {
        width: 230px;
        height: 242px; }

    .col-left-title {
        display: block;
        position: absolute;
        width: 63px;
        height: 29px;
        left: 0;
        top: 84%;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #FFFBFB; }

    .col-right-top-title {
        display: block;
        position: absolute;
        width: 63px;
        height: 29px;
        left: 10%;
        top: 70%;
        bottom: 10%;
        right: 0%;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #FFFBFB; }

    .col-right-bottom-title1 {
        display: block;
        position: absolute;
        width: 63px;
        height: 29px;
        left: 10%;
        top: 70%;
        bottom: 10%;
        right: 0%;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #FFFBFB; }

    .col-right-bottom-title2 {
        display: block;
        position: absolute;
        width: 63px;
        height: 29px;
        left: 58%;
        top: 70%;
        bottom: 10%;
        right: 0%;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #FFFBFB; } }
