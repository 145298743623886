.header-container {
    .header {
        &-images {
            &-logo img {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                height: 80px;
                top: 10px;
                @include gt-xs {
                    height: 82.5px; }
                @include gt-sm {
                    height: 85px; }
                @include gt-md {
                    height: 87.5px; }
                @include gt-lg {
                    height: 90px; } }

            &-breil {
                // background-image: url('/Breil_top.png')
                overflow: hidden;
                height: 100px;
                @include gt-xs {
                    height: 105px; }
                @include gt-sm {
                    height: 110px; }
                @include gt-md {
                    height: 115px; }
                @include gt-lg {
                    height: 120px; } } }

        &-text {
            position: absolute;
            font-family: Open sans;
            color: #135272;
            top: 75px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 18px;
            @include gt-xs {
                top: 77.5px;
                font-size: 19px; }
            @include gt-sm {
                top: 80px;
                font-size: 20px; }
            @include gt-md {
                top: 82.5px;
                font-size: 21px; }
            @include gt-lg {
                top: 85px;
                font-size: 22px; } }

        &-menu {
            width: 100%;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            background-color: rgba(41, 105, 138, 0.19);
            @include gt-lg {
                height: 60px; } } }

    .menu {
        &-item {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            position: relative;
            &::before {
                content: "";
                display: none;
                position: absolute;
                bottom: 5px;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: rgba(41, 105, 138, 0.95); }
            &.selected {
                // background-color: rgba(41, 105, 138, 0.25)
                &::before {
                    display: block; } }

            @include gt-xs {
                width: 30%; }
            @include gt-sm {
                width: 20%; }
            &:hover .dropdown-content {
                display: block; }

            &:hover .dropbtn {
                background-color: #3e8e41; }

            a {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                color: #000000;
                text-decoration: none;
                @include gt-xs {
                    font-size: 13px; }
                @include gt-sm {
                    font-size: 14px; }
                @include gt-md {
                    font-size: 16px; }
                @include gt-lg {
                    font-size: 18px; } } } }

    .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-top: 1.5%;
        margin-right: 2%;
        background-color: #000000; }

    .link {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #000000; }

    .dropdown-content {
        display: none;
        position: absolute;
        width: 125px;
        height: 160px;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(41, 105, 138, 0.19);
        margin-top: 43%;
        border-radius: 5%;

        a {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: #135272;
            padding: 12px 16px;
            text-decoration: none;
            display: block;

            &:hover {
                background-color: #ddd; } } } }

.languages {
    position: absolute;
    top: 25px;
    right: 10px;
    ul {
        li {
            display: inline-block;
            height: 30px;
            width: 30px;
            line-height: 30px;
            margin: 5px;
            text-align: center;
            border-radius: 50%;
            background-color: rgba(41, 105, 138, 0.19);
            font-size: 11px;
            @include gt-xs {
                height: 40px;
                width: 40px;
                line-height: 40px;
                font-size: 12px; }
            @include gt-sm {
                margin: 7.5px;
                height: 45px;
                width: 45px;
                line-height: 45px;
                font-size: 13px; }

            .lang-button {
                cursor: pointer;
                color: #135272;
                background: none;
                border: none; } } } }
