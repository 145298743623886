.collaborators {
    &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 60px;
        margin-bottom: 70px; }

    &-img-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center; }

    &-name {
        text-align: center;
        margin-top: 10px;
        span {
            text-align: center;
            font-family: Reenie Beanie;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            color: #276A8C; } }

    &-img {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        @include gt-xs {
            width: 125px;
            height: 125px; }
        @include gt-sm {
            width: 140px;
            height: 140px; }
        @include gt-md {
            width: 170px;
            height: 170px; } } }
