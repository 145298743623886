$size: 1.4em;
$dotCount: 10;
$animationTime: 2.5s;

.peeek-loading {
	background-color: rgba(41, 105, 138, 1);
	overflow: hidden;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	z-index: 9999;

	& ul {
		position: absolute;
		left: calc(50% - 0.7em);
		top: calc(50% - 4.2em);
		display: inline-block;
		text-indent: $size*2; }

	& ul li:after {
		width: $size;
		height: $size;
		background-color: #fff;
		border-radius: 100%; }

	& ul:after {
		width: $size;
		height: $size;
		background-color: #fff;
		border-radius: 100%; }

	& ul li:after {
		content: "";
		display: block; }

	& ul:after {
		content: "";
		display: block; }

	& ul:after {
		position: absolute;
		top: $size*2; }

	& li {
		position: absolute;
		padding-bottom: $size*4;
		top: 0;
		left: 0; } }

// Creating Circle and Animation Delay
@for $i from 1 through 10 {
	.peeek-loading li:nth-child(#{$i}) {
		$deg: ($i - 1)*(360deg/$dotCount);
		transform: rotate($deg);
		animation-delay: $animationTime*$i/$dotCount/2; }

	.peeek-loading li:nth-child(#{$i}):after {
		animation-delay: $animationTime*$i/$dotCount/2; } }

// Animations
.peeek-loading li {
	animation: dotAnimation $animationTime infinite; }

@keyframes dotAnimation {
	0% {
		padding: 0 0 $size*4 0; }
	5% {
		padding: $size*2 0; }
	50% {
		padding: $size*2 0; }
	55% {
		padding: 0 0 $size*4 0; }
	100% {
		padding: 0 0 $size*4 0; } }

.peeek-loading li:after {
	animation: dotAnimationTwo $animationTime infinite; }

@-webkit-keyframes dotAnimationTwo {
	0%, 55%, 100% {}
	opacity: 1;
	transform: scale(1);

	5%, 50% {}
	opacity: .5;
	transform: scale(0.5); }
