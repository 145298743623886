.radio-container {
    width: 100%;
    padding: 2%; }


.singer-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    text-decoration: none; }
