.whoWeAre {
    &-container {
        width: 100%;
        margin-bottom: 50px; }

    &-image {
        margin-top: 40px;
        text-align: center;
        img {
            max-width: 100%;
            border-radius: 10px;
            max-height: 400px; } }

    &-text-wrapper {
        padding: 20px;

        img {
            max-width: 100%;
            height: auto;
            border-radius: 10px; }

        h1, h2, h3, h4 {
            margin: 20px; }

        a {
            color: #135272; } }

    &-col {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        padding-top: 25px;
        img {
            width: 526px;
            height: 628px; } } }

.title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    color: #000000; }

.paragraph {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    color: #000000; }
