.news-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    margin-bottom: 5%;


    .title-line {
        width: 40%;
        height: 2px;
        background-color: #29698A;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-top: 2.5%; }

    .title-text {
        width: 20%;
        text-align: center; }

    .title-text {
        span {
            font-family: Reenie Beanie;
            font-style: normal;
            font-weight: 500;
            font-size: 48px;
            line-height: 48px;
            color: #276A8C; } }
    .slider-img {
        width: 25%; }

    .news-arrows {
        width: 100px; }

    .news-home-img {
        width: 294px;
        height: 271px; }

    .news-home-slider-container {
        margin-top: 2%; }

    .home-news-flex {
        display: flex;
        flex-direction: column;
        margin-top: 4%; }


    .news-home-slider-img-wrapp {
        width: 294px;
        position: relative; }

    .news-home-slider-img-wrapp img {
        width: 100%;
        object-fit: contain; }

    .home-news-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 50%; }

    .news-home-slide-title-wrapp {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 3%;
        padding-top: 1%;
        position: absolute;
        width: 270px;
        height: 50px;
        margin: auto auto;
        left: 0;
        top: 1;
        bottom: 0;
        background: rgba(196, 196, 196, 0.5);
        border-radius: 10px;
        margin-left: 2.9%;
        margin-bottom: 5%;
        span {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #000000;
            text-align: left; } } }




