
.team-page {
    &-container {
        width: 100%;
        margin-bottom: 50px; }

    &-image {
        margin-top: 40px;
        text-align: center;
        img {
            max-width: 90%;
            max-height: 400px;
            border-radius: 10px; } }

    &-text-wrapper {
        padding: 20px;
        font-size: 1.1em;
        overflow: hidden;

        img {
            max-width: 100%;
            height: auto;
            border-radius: 10px; }

        h1, h2, h3, h4 {
            margin: 20px; }

        a {
            color: #135272; } }

    &-title {
        text-align: center;
        margin-bottom: 50px; } }
