.live-container-perf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin-top: 5%; }


.youtube-iframe-perf {
    margin: 0 auto;
    border-radius: 2%; }

.live-title-wrapper-perf {
    margin: 1% 15%; }

.live-title-wrapper-perf {
    span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 33px;
        color: #AB0B0B; } }
