.news-container {
    width: 100%; }


.news-wrapper {
    width: 100%;
    margin-top: 3%; }

.news-img-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
    img {
        width: 338px;
        height: 320px; } }

.news-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 3%;
    width: 310px;
    height: 60px;
    border-radius: 10px;
    margin: 0;
    span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #000000;
        text-align: left;
        margin-bottom: 5%; } }

.news-title {
    font-size: 24px;
    line-height: 33px; }
