.home-page {
    .program-container {
        width: 100%;
        display: flex;
        margin-top: 90px;
        margin-bottom: 90px;
        flex-direction: column;

        .title-text {
            text-align: center; }

        .splide__slide {
            width: 350px;
            overflow: hidden;
            border-radius: 10px;
            &:hover {
                .prog-slider-img {
                    transform: scale(1.1); }
                .slide-title-wrapp {
                    width: 320px;
                    height: 80px;
                    padding: 10px 40px; } }

            .prog-slider-img {
                transition: all .35s ease;
                height: 220px; } }

        .prog-slider-container {
            margin-top: 2%;
            position: relative; }

        .splide__arrow--prev {
            margin-top: 5%;
            outline: none; }

        .splide__arrow--next {
            margin-top: 6%;
            outline: none; }

        // .slide-title-wrapp
        //     position: absolute
        //     left: 50%
        //     padding: 10px 20px
        //     transform: translateX(-50%)
        //     width: 270px
        //     height: 70px
        //     bottom: 20px
        //     border-radius: 10px
        //     background: rgba(196, 196, 196, 0.85)
        //     transition: all .35s ease
        //     span
        //         font-family: "Open Sans"
        //         font-style: normal
        //         font-weight: 800
        //         text-align: center
        //         color: #000000
        //         text-align: left

        .slider-img {
            width: 25%; }

        .program-date {
            color: #000000; } } }
