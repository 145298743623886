.title {

    &-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        @include gt-xs {
            margin-bottom: 30px; } }

    &-line {
        width: 20%;
        height: 2px;
        background-color: #000;
        margin-top: 10px;
        @include gt-xs {
            margin-bottom: 50px;
            margin-top: 20px;
            width: 25%; }
        @include gt-sm {
            width: 35%; }
        @include gt-md {
            width: 50%; } }

    &-text {
        width: 100%;
        text-align: center;
        position: relative;

        span {
            font-family: Open sans;
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 22px;
            color: #000;
            @include gt-xs {
                font-size: 24px;
                line-height: 24px; }
            @include gt-sm {
                font-size: 26px;
                line-height: 26px; }

            a {
                color: #000; } } }

    &-img-text {
        width: 40%;
        text-align: center;
        span {
            font-family: Reenie Beanie;
            font-style: normal;
            font-weight: 500;
            font-size: 48px;
            line-height: 48px;
            color: #276A8C; } } }

.image-tv {
    position: absolute;
    top: -50;
    bottom: 50;
    left: 50;
    right: 50; }
