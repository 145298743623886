
@import "variables";

@mixin gt-xs {
  @media screen and (min-width: $bp-xs) {
    @content; } }

@mixin lt-xs {
  @media screen and (max-width: $bp-xs) {
    @content; } }



@mixin gt-sm {
  @media screen and (min-width: $bp-sm) {
    @content; } }


@mixin lt-sm {
  @media screen and (max-width: $bp-sm) {
    @content; } }



@mixin gt-md {
  @media screen and (min-width: $bp-md) {
    @content; } }


@mixin lt-md {
  @media screen and (max-width: $bp-md) {
    @content; } }



@mixin gt-lg {
  @media screen and (min-width: $bp-lg) {
    @content; } }


@mixin lt-lg {
  @media screen and (max-width: $bp-lg) {
    @content; } }



@mixin gt-xl {
  @media screen and (min-width: $bp-xl) {
    @content; } }


@mixin lt-xl {
  @media screen and (max-width: $bp-xl) {
    @content; } }



@mixin gt-custom($width) {
  @media screen and (min-width: $width) {
    @content; } }



@mixin lt-custom($width) {
  @media screen and (max-width: $width) {
    @content; } }



@mixin theme {
  body.theme-euro & {
    $primary    : #f2b632 !global;
    $primary2   : #FFAF00 !global;
    $dark-font  : #677077 !global;
    $light-font : #efeff1 !global;
    $main       : #252839 !global;
    $body       : #efeff1 !global;
    @content; }

  body.theme-sport & {
    $primary    : #99CD4E !global;
    $primary2   : #7ACF00 !global;
    $dark-font  : #343434 !global;
    $light-font : #FFFFFF !global;
    $main       : #343434 !global;
    $body       : #FFFFFF !global;
    @content; }

  body.theme-ocean & {
    $primary    : #4EB1BA !global;
    $primary2   : #00ABBA !global;
    $dark-font  : #343434 !global;
    $light-font : #FFFFFF !global;
    $main       : #222930 !global;
    $body       : #FFFFFF !global;
    @content; }

  body.theme-spain & {
    $primary    : #E25D33 !global;
    $primary2   : #E33600 !global;
    $dark-font  : #282827 !global;
    $light-font : #FFFFFF !global;
    $main       : #282827 !global;
    $body       : #FFFFFF !global;
    @content; }

  body.theme-dream & {
    $primary    : #9068be !global;
    $primary2   : #691AC4 !global;
    $dark-font  : #201A26 !global;
    $light-font : #F4EBFF !global;
    $main       : #201A26 !global;
    $body       : #C6BCD1 !global;
    @content; } }


@mixin darktheme {
  body.theme-night & {
    $primary    : #97743a !global;
    $primary2   : #B8494D !global;
    $dark-font  : #201A26 !global;
    $light-font : #BDBDBD !global;
    $main       : #1A1A1A !global;
    $body       : #141414 !global;
    @content; } }


