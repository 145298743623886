.perfTvProgram-container {
    width: 100%; }


.perfTvProgram-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2%; }

.perfTvProgram-img-wrapp {
    width: 32%;
    position: relative;
    margin-top: 2%;
    img {
        width: 338px;
        height: 320px; } }
.perfTvProgram-title-wrapp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 3%;
    position: absolute;
    width: 310px;
    height: 60px;
    margin: auto auto;
    left: 0;
    top: 1;
    bottom: 0;
    background: rgba(196, 196, 196, 0.5);
    border-radius: 10px;
    margin-left: 4%;
    margin-bottom: 4%;
    span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        text-align: left; } }
