
.contacts-page {
	font-family: 'Open sans';
	&-text {
		text-align: center;
		margin-top: 110px;
		font-size: 1.2em; }
	a {
		color: #000; }

	&-flag {
		width: 45px; } }
