.footer {
    width: 100%;
    background: rgba(17, 17, 17, 0.1);
    padding: 5px 50px;
    display: grid;
    grid-template-rows: auto;
    grid-template-areas: "contacts""copyright";
    @include gt-xs {
        padding: 10px 70px;
        grid-template-areas: "contacts copyright"; }
    @include gt-sm {
        padding: 15px 130px; }

    &-contacts {
        text-align: center;
        grid-area: contacts;
        @include gt-xs {
            text-align: left; } }
    &-logo-wrapper {
        display: grid;
        justify-content: center;
        text-align: center;
        align-content: space-around;
        grid-area: copyright;
        @include gt-xs {
            justify-content: end;
            text-align: right; }
        img {
            width: 60px; } } }

.title {
    span {
        display: block;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px; } }

.address {
    margin-top: 20px;
    @include gt-xs {
        margin-top: 10px; }

    span {
        display: block;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        a {
            color: #000; } } }

.share {
    &-items {
        margin-top: 10px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        @include gt-xs {
            justify-content: left; } }
    &-item {
        float: left;
        margin-right: 10px;
        img {
            width: 24px; } } }

.copyRight {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    @include gt-xs {
        text-align: right; }
    span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 19px;
        color: #000;
        @include gt-xs {
            font-size: 12px; } } }

.becomeTitle {
    margin-left: auto;
    span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px; } }

.line {
    width: 100%;
    height: 1px;
    margin: 0 auto;
    background-color: #135272; }

.deepmindsystems {
    width: 100%;
    min-height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #41474a;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #ffffff;
    margin-right: 10px;
    @include gt-xs {
        font-size: 12px; }
    @include gt-sm {
        font-size: 13px; }
    @include gt-md {
        font-size: 14px; }
    a {
        color: #74badf; } }
